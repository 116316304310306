const prefix = '@qes/login';

export const LOGIN_REQUEST = `${prefix}/LOGIN_REQUEST`;
export const LOGIN_SUCCESS = `${prefix}/LOGIN_SUCCESS`;
export const LOGIN_MFA_SETUP = `${prefix}/LOGIN_MFA_SETUP`;
export const LOGIN_MFA_CHALLENGE = `${prefix}/LOGIN_MFA_CHALLENGE`;
export const LOGIN_FAILURE = `${prefix}/LOGIN_FAILURE`;
export const LOGIN_LOGOUT = `${prefix}/LOGIN_LOGOUT`;

export const MFA_CHALLENGE_REQUEST = `${prefix}/MFA_CHALLENGE_REQUEST`;
export const MFA_CHALLENGE_SUCCESS = `${prefix}/MFA_CHALLENGE_SUCCESS`;
export const MFA_CHALLENGE_FAILURE = `${prefix}/MFA_CHALLENGE_FAILURE`;

export const MFA_SETUP_REQUEST = `${prefix}/MFA_SETUP_REQUEST`;
export const MFA_SETUP_SUCCESS = `${prefix}/MFA_SETUP_SUCCESS`;
export const MFA_SETUP_FAILURE = `${prefix}/MFA_SETUP_FAILURE`;

export const RECOVER_PASSWORD_REQUEST = `${prefix}/RECOVER_PASSWORD_REQUEST`;
export const RECOVER_PASSWORD_SUCCESS = `${prefix}/RECOVER_PASSWORD_SUCCESS`;
export const RECOVER_PASSWORD_FAILURE = `${prefix}/RECOVER_PASSWORD_FAILURE`;

export const USER_LOGOUT = `${prefix}/USER_LOGOUT`;
