import axios from 'axios';
import * as types from './login.types';

const loginServiceBaseUrl = process.env.NEXT_PUBLIC_WORKFLOW_SERVICE_URL;

const humanErrorMsg = (error) => {
  if (error.includes('InvalidParameterException')) {
    return 'Missing credentials';
  }
  if (error.includes('UserNotFoundException') || error.includes('NotAuthorizedException')) {
    return 'Wrong credentials';
  }

  return error;
};

export const login = ({ email, password, reset_mfa_token }) => {
  return async (dispatch) => {
    dispatch({
      type: types.LOGIN_REQUEST
    });

    try {
      await axios({
        baseURL: loginServiceBaseUrl,
        url: '/signin',
        method: 'POST',
        data: { email, password, reset_mfa_token }
      })
        .then((res) => {
          const loginDetails = res.data.login || {};
          const mfaDetails = res.data.mfa || {};
          const isMFARequired = !!mfaDetails.session || !!mfaDetails.setup_mfa_code;
          const isMFASetup = isMFARequired && mfaDetails.name == 'FORCE_MFA_SETUP';

          if (isMFARequired) {
            const mfaType = isMFASetup ? types.LOGIN_MFA_SETUP : types.LOGIN_MFA_CHALLENGE;
            dispatch({
              type: mfaType,
              payload: {
                loginDetails,
                mfaDetails: {
                  ...mfaDetails,
                  username: email
                }
              }
            });
          } else {
            dispatch({
              type: types.LOGIN_SUCCESS,
              payload: {
                tokenId: loginDetails.token,
                tokenAccess: loginDetails.access,
                tokenRefresh: loginDetails.refresh,
                userDetails: loginDetails.userDetails
              }
            });
          }
        })
        .catch((err) => {
          let message = "We couldn't process the request";
          if (err.response.data.error) {
            message = err.response.data.error;
          }
          return dispatch({
            type: types.LOGIN_FAILURE,
            payload: {
              error: humanErrorMsg(message)
            }
          });
        });
    } catch (err) {
      return dispatch({
        type: types.LOGIN_FAILURE,
        payload: {
          error: humanErrorMsg(err.error)
        }
      });
    }
  };
};

export const refreshRequest = ({ refreshToken, username }) => {
  return axios({
    skipAuthRefresh: true,
    baseURL: loginServiceBaseUrl,
    url: '/refresh',
    method: 'POST',
    data: { refreshToken, username }
  }).then((response) => {
    return {
      tokenId: response.data.token,
      tokenAccess: response.data.access,
      tokenRefresh: response.data.refresh,
      userDetails: response.data.userDetails
    };
  });
};

export const recoverPassword = ({ recoverEmail }) => {
  return async (dispatch) => {
    dispatch({
      type: types.RECOVER_PASSWORD_REQUEST
    });

    try {
      const { data = {} } = await client.post(`/accounts/users`, { recoverEmail });
      const { password = {} } = data;

      return dispatch({
        type: types.RECOVER_PASSWORD_SUCCESS,
        payload: {
          password
        }
      });
    } catch (err) {
      return dispatch({
        type: types.RECOVER_PASSWORD_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const mfaChallenge = ({ username, session, code }) => {
  return async (dispatch) => {
    dispatch({
      type: types.MFA_CHALLENGE_REQUEST
    });

    try {
      await axios({
        baseURL: loginServiceBaseUrl,
        url: '/mfa/challenge',
        method: 'POST',
        data: { username, mfa_session: session, mfa_token_code: code }
      })
        .then((res) => {
          return dispatch({
            type: types.MFA_CHALLENGE_SUCCESS,
            payload: {
              tokenId: res.data.token,
              tokenAccess: res.data.access,
              tokenRefresh: res.data.refresh,
              userDetails: res.data.userDetails
            }
          });
        })
        .catch((err) => {
          let message = "We couldn't process the request";
          if (err.response.data.error) {
            message = err.response.data.error;
          }
          return dispatch({
            type: types.MFA_CHALLENGE_FAILURE,
            payload: {
              error: message
            }
          });
        });
    } catch (err) {
      return dispatch({
        type: types.MFA_CHALLENGE_FAILURE,
        payload: {
          error: err.message || err.response.data.error
        }
      });
    }
  };
};

export const mfaSetup = ({ username, accessToken, code }) => {
  return async (dispatch) => {
    dispatch({
      type: types.MFA_SETUP_REQUEST
    });

    try {
      await axios({
        baseURL: loginServiceBaseUrl,
        url: '/mfa/setup',
        method: 'POST',
        data: { username, accessToken, mfa_token_code: code }
      })
        .then((res) => {
          return dispatch({
            type: types.MFA_SETUP_SUCCESS,
            payload: {
              mfaDetails: {
                username,
                setup_successful: true
              }
            }
          });
        })
        .catch((err) => {
          let message = "We couldn't process the request";
          if (err.response.data.error) {
            message = err.response.data.error;
          }
          return dispatch({
            type: types.MFA_SETUP_FAILURE,
            payload: {
              error: message
            }
          });
        });
    } catch (err) {
      return dispatch({
        type: types.MFA_SETUP_FAILURE,
        payload: {
          error: err.message || err.response.data.error
        }
      });
    }
  };
};

export const userLogout = () => {
  return async (dispatch) => {
    dispatch({
      type: types.USER_LOGOUT
    });
  };
};
